.kitchen{
  position: relative;
  overflow: hidden;

  @media screen and (max-width:$zoom120){
    @media screen and (max-height:$zoom120Height){
      height:100vh;
    }
  }

  @media screen and (max-width:$md-width) {
    height:auto;
  }

  &__wrapper{
    display: flex;
    transition:$transition;

    @media screen and (max-width:$md-width) {
      flex-direction: column;
    }

    &_absolute{
      position: absolute;
      right:-200%;
      width: 0;
      height:100%;
      top:0;
      left:0;
      opacity: 0;
    }

    &_active{
      opacity: 1;
      right:0;
      left:0;
      width:100%;
      z-index: 10;
    }
  }

  &__item{
    flex:1;
    @media screen and (max-width:$md-width) {
      flex: unset;
      height: 100%;
    }

    &_padding{
      padding:4rem 0;

      @media screen and (max-width:$zoom120){
        @media screen and (max-height:$zoom120Height){
          padding:2rem 0;
        }
      }

      @media screen and (max-width:$md-width) {
        padding:0 0 2rem 0;
      }
    }

    &-wrapper{
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding:0 0 0 4rem;

      @media screen and (max-width:$md-width) {
        padding:0;
      }

      & > .btn{
        @media screen and (max-width:$md-width) {
          margin-left:1.5rem;
        }
      }
    }

    &_back{
      position: relative;
      margin-left:2rem;

      @media screen and (max-width:$md-width) {
        margin-top:-3rem;
        color:#fff;
        text-align: left;
        margin-left:3.5rem;
      }

      &::before{
        position: absolute;
        content:'';
        width: 16px;
        height: 40px;
        background-image: url('../../assets/img/any/variant-arrow.svg');
        background-size:contain;
        background-position: center;
        background-repeat: no-repeat;
        top: calc(50% - 20px);
        left: -2rem;
        cursor: pointer;
        @media screen and (max-width:$md-width) {
          background-image: url('../../assets/img/any/variant-arrow-wht.svg');
        }
      }


    }

    &_first{
      @media screen and (max-width:$md-width){
        background-image: url('../../assets/img/any/kitchen_2.jpg') !important;
      }
    }
  }

  &__text{
    padding:4rem 0 2rem;
    max-width: 600px;
    width: 100%;

    @media screen and (max-width:$zoom120){
      @media screen and (max-height:$zoom120Height){
        padding:1rem 0;
      }
    }

    @media screen and (max-width:$md-width) {
      padding:2rem 1.5rem;
    }

    p{
      font-weight: 300;
      font-size: 16px;
      line-height: 18px;
      padding:.25rem 0;
    }

    &_mobile{
      @media screen and (max-width:$md-width) {
        padding:2rem 2.5rem 0;
        margin-bottom: 3rem;
      }
    }

    &_btn{
      padding:0 0 0 2.5rem;
    }

    &_scroll{
      max-height: 450px;
      height: 100%;
      overflow: hidden;
      overflow-y: scroll;
      margin-top: 1rem;
      padding-top:1rem;
    }
  }

  &__list{

    li{
      position: relative;
      margin: 0 0 1rem 0;
      font-size: 16px;
      line-height: 18px;
      color: #144784;
      font-weight: normal;

      &:before{
        position: absolute;
        content:'';
        width: 10px;
        height: 10px;
        background: #144784;
        left:-20px;
        top:2.5px;
        border-radius: 100%;
      }
    }

    &_blue{
      font-size: 16px;
      line-height: 18px;
      color: #144784;
      font-weight: normal;
    }

    &_bottom{
      margin:0 0 1rem 0;
    }
  }
}