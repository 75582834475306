.variant{
  padding:4rem 0 4rem 4rem;
  @media screen and (max-width:$zoom120){
    @media screen and (max-height:$zoom120Height){
      padding:2rem 0 2rem 4rem;
    }
  }

  @media screen and (max-width:$md-width) {
    padding:0 1.5rem 1rem;
  }

  &__title{
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #144784;
    margin-bottom: 2rem;
  }

  &__item{
    margin-bottom:1rem;
    background:linear-gradient(68.2deg, #F8FCFE 6.74%, rgba(172, 224, 255, 0.36) 94.55%);
    padding:1.5rem;
    max-width: 600px;
    width: 100%;
    position: relative;
    cursor: pointer;

    @media screen and (max-width:$zoom120){
      @media screen and (max-height:$zoom120Height){
        padding:.5rem;
      }
    }

    &::after{
      position: absolute;
      content:'';
      width: 16px;
      height: 40px;
      background-image: url('../../assets/img/any/variant-arrow.svg');
      background-size:contain;
      background-position: center;
      background-repeat: no-repeat;
      top: calc(50% - 20px);
      right: 1rem;
    }

    &-title{
      font-weight: 500;
      font-size: 20px;
      line-height: 129.9%;
      color: #144784;
    }

    &-subtitle{
      font-weight: 300;
      font-size: 16px;
      line-height: 129.9%;
      color: #144784;
      margin-top:.5rem;
    }
  }

}