.audit{
  background: linear-gradient(180deg, #F8F8F8 -27.18%, rgba(248, 248, 248, 0) 88.56%);

  &__wrapper{
    padding:4rem 0;

    @media screen and (max-width:$md-width) {
      padding:2rem 0;
    }
  }

  &__table{
    padding:4rem 0;

    @media screen and (max-width:$zoom120){
      @media screen and (max-height:$zoom120Height){
        padding:2rem 0;
      }
    }
  }

  &__item{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding:2rem 0;

    @media screen and (max-width:$md-width) {
      flex-flow:column;
    }

    &_blue{
      background: linear-gradient(68.2deg, #F8FCFE 6.74%, rgba(172, 224, 255, 0.36) 94.55%);
    }

    &_red{
      background: linear-gradient(58.92deg, rgba(255, 225, 223, 0.49) 21.89%, rgba(252, 131, 123, 0.52) 99.88%);
    }
  }

  &__title{
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #144784;
    margin-bottom: 1rem;
  }

  &__column{
    flex:1;
    font-size: 13px;
    line-height: 15px;
    padding:0 1rem;
    position: relative;

    @media screen and (max-width:$md-width) {
      padding: 0 1.5rem;
      margin: 0 0 2rem 0;
    }

    &:first-child{
      padding:0 1rem 0 0;

      @media screen and (max-width:$md-width) {
        padding: 0 1.5rem;
      }
    }

    &_check{
      @media screen and (max-width:$md-width) {
        padding: 0 2.5rem;
        margin: 0 0 1.5rem 0;
      }
      &::before{
        position: absolute;
        content:'';
        width: 14px;
        height: 10px;
        background-image: url('../../assets/img/any/check.svg');
        background-repeat: no-repeat;
        top: 2px;
        left: -4px;

        @media screen and (max-width:$md-width) {
          left: 24px;
        }
      }
    }
  }
}