.title{
  font-size: 30px;
  line-height: 50px;
  text-align: center;

  @media screen and (max-width:$lg-width){
    font-size: 24px;
    line-height: 32px;
  }
  @media screen and (max-width:$md-width){
    font-size: 20px;
    line-height: 30px;
  }

  &__mobile{
    @media screen and (max-width:$md-width){
      position: relative;
      margin-top: -55px;
      width: 100%;
      background-color: rgba(255,255,255,.85);
      padding: 10px 0 1rem 1.5rem;
      text-align: left;
    }
  }

  &-primary{
    color:$primaryColor;
  }

  &-default{
    color:$default-color;
  }

  &_left{
    text-align: left;
  }

  &_underline{
    position: relative;
    &::after{
      position: absolute;
      content:'';
      width:70%;
      height:2px;
      background:#154885;
      bottom:-10px;
      left:0;
    }
  }

  &_reset{

  }
}