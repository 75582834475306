.problem{
  color:$primaryColor;
  margin:3rem 0;

  &-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin:1rem 0 5rem 0;
    overflow: hidden;
    padding:2rem 0;

    @media screen and (max-width:$lg-width){
      flex-flow:column;
      padding:2rem 0 0;
    }
  }

  &-border{
    min-width:305px;
    width:100%;
    height:170px;
    border: 1px solid #5DB6E9;
    position: relative;
    z-index: 10;
    right: 19rem;

    @media screen and (max-width:$sm-width){
      min-width:270px;
      right: 17rem;
      height:140px;
    }
  }

  &-group{
    display: flex;
    align-items: center;
    background:#fff;
    max-width:380px;
    width:100%;
    position: relative;
    z-index: 20;

    @media screen and (max-width:$sm-width){
      background:transparent;
    }
  }

  &-item{
    position: relative;
    display: flex;
    align-items: center;
    width: 33.3%;
    overflow: hidden;

    @media screen and (max-width:$lg-width){
      width: 100%;
      margin:0 0 2rem;
      position: relative;
      left: calc(50% - 16rem);

      &:last-child{
        margin:0;
      }
    }

    @media screen and (max-width:$sm-width){
      position: unset;
      left: unset;
    }

    &_group{
      margin: 0 0 0 22px;
    }

    &_img{
      width:90px;
      height:90px;
      background: #fff;
      flex:0 0 auto;

      svg{
        width:100%;
        height:100%;
      }
    }
    &_title{
      font-weight: 500;
      font-size: 36px;
      color: $primaryColor;

      @media screen and (max-width:$sm-width){
        font-size: 23px;
      }
    }
    &_text{
      font-size: 18px;
      line-height: 22px;
      color: $primaryColor;
      margin:22px 0 0;
      min-width:265px;
      width:100%;

      @media screen and (max-width:$sm-width){
        font-size: 14px;
        line-height: 22px;
        margin:12px 0 0;
      }
    }
  }
}