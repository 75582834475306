.hamburger{
  display: none;
  width:24px;
  height:24px;

  @media screen and (max-width:$lg-width){
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg{
    width:24px;
    height:24px;
  }
}