.table{

  @media (max-width:$zoom120){
    @media screen and (max-height:$zoom120Height){
      height:100vh;
    }
  }

  @media screen and (max-width:$md-width) {
    height: auto;
  }

  &__wrapper{
    height: 100%;
    display: flex;

    @media screen and (max-width:$md-width) {
      flex-direction: column-reverse;
    }

    &_right{
      flex-flow:column;
      align-items: flex-start;
    }
  }

  &__item{
    flex: 1;
    @media screen and (max-width:$md-width) {
      flex: unset;
    }

    &_img{
      background-size: cover!important;
      background-position: center!important;
      @media screen and (max-width:$md-width) {
        min-height: 215px;
        max-height: 215px;
      }
    }

    &_center{
      display: flex;
      flex-flow:column;
      align-items: flex-end;
      justify-content: center;
    }

    &_background{
      background:#F8F8F8;
    }

    &_padding{
      padding:5rem 0;

      @media screen and (max-width:$zoom120){
        @media screen and (max-height:$zoom120Height){
          padding:1rem 0;
        }
      }
      @media screen and (max-width:$md-width) {
        padding:0;
      }

    }
  }

  &__title{
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: #797979;
    margin-top:20px;
    max-width:400px;
    width:100%;

    @media screen and (max-width:$md-width) {
      font-weight: 300;
      font-size: 16px;
      line-height: 18px;
      padding:0 1.5rem;
    }
  }
}

