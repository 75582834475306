.modal{
  position: fixed;
  width:100%;
  height:100%;
  top:0;
  left:0;
  opacity: 0;
  visibility: hidden;
  background:rgba(255,255,255,.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:100;
  padding:1rem;
  transition:$transition;

  &.active{
    visibility: unset;
    opacity: 1;
  }

  &-wrapper{
    position: relative;
    background-color: #fff;
    padding:2rem;
    border-radius: 10px;
    max-width:400px;
    width:100%;
    background-repeat: no-repeat;
    background-position: bottom right;
    box-shadow: 0 6px 27px rgba(150, 150, 150, 0.25);
  }

  &-closer{
    position: absolute;
    top:.5rem;
    right:.5rem;
    width:16px;
    height:16px;
    cursor: pointer;

    svg{
      width:100%;
      height: 100%;

      path{
        fill:#000;
      }
    }
  }

  &-title{
    font-weight: bold;
    font-size: 36px;
    line-height: 41px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    @media screen and (max-width:$lg-width){
      font-size: 24px;
    }
  }

  &-group{
    margin:2rem 0;
  }

  & input{
    width:100%;
    height:54px;
    padding:1rem;
    border: 1px solid #1A5692;
    border-radius: 50px;
    background: transparent;
    transition:$transition;

    &:focus{
      background: #fff;
    }
  }

}