.btn{
  font-weight: bold;
  font-size: 18px;
  max-width:250px;
  height:60px;
  width:100%;
  display: flex;
  justify-content: center;
  align-items:center;
  transition:$transition;
  cursor:pointer;

  @media screen and (max-width:$md-width){
    max-width:220px;
    height:50px;
    font-size: 16px;
  }

  &__modal{
    border-radius: 50px;
    max-width: 170px;
    height: 50px;
  }

  &__red{
    color:#fff;
    background: #E1524C;
    box-shadow:0 25px 35px -20px #E1524C;

    &:hover,
    &:focus{
      color:darker(#ffffff, 30);
      background:darker(#E1524C, 30);
      box-shadow:0 25px 35px -20px darker(#E1524C, 30);
    }
  }

  &__light{
    color:#ffffff;
    background: #FC837B;
    box-shadow:0 25px 35px -20px #FC837B;

    &:hover,
    &:focus{
      background:darker(#FC837B, 30);
      box-shadow:0 25px 35px -20px darker(#FC837B, 30);
    }
  }

  &__darker{
    color:#fff;
    background: #E1524C;
    box-shadow:0 25px 35px -20px #E1524C;

    &:hover,
    &:focus{
      color:darker(#ffffff, 30);
      background:darker(#E1524C, 30);
      box-shadow:0 25px 35px -20px darker(#E1524C, 30);
    }
  }

  &__md{
    width: 155px;
    height: 45px;
  }
}