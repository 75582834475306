@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@import "utils/vars";
@import "utils/mixins";
@import "utils/reset";


body { font-family:$mainFont; font-size: 1rem; font-weight: 400 }

.wrapper {
  display: flex; flex-direction: column; margin: 0 auto; min-height: 100vh; overflow: hidden;
  &.blur{
    filter:blur(5px);
  }
}

header{position: sticky; top:0; z-index:100 }

main{flex: 1 0 auto; z-index:20; position: relative }

footer{ flex: 0 0 auto }

@import "blocks/header";
@import "blocks/footer";

@import "blocks/hamburger";
@import "blocks/logo";
@import "blocks/modal";
@import "blocks/menu";
@import "blocks/menu-mobile";
@import "blocks/container";
@import "blocks/banner";
@import "blocks/problem";
@import "blocks/title";
@import "blocks/btn";
@import "blocks/table";
@import "blocks/stages";
@import "blocks/kitchen";
@import "blocks/variant";
@import "blocks/audit";
@import "blocks/introduction";
@import "blocks/study";
@import "blocks/experts";
@import "blocks/slider";
@import "blocks/question";
@import "blocks/news";
@import "blocks/study-mobile";


