.study{
  @media screen and (max-width:$md-width) {
    display: none;
  }

  &__wrapper{
    display: flex;
    transition:$transition;
  }

  &__slide{
    &-title{
      font-weight: 500;
      font-size: 18px;
      line-height: 50px;
      margin:4rem 0 2rem;

      @media screen and (max-width:$zoom120){
        @media screen and (max-height:$zoom120Height){
          margin:2rem 0;
        }
      }

      @media screen and (max-width:$md-width) {
        margin:0 0 1rem 0;
      }
    }
  }

  &__list{
    max-width: 520px;
    width: 100%;

    li{
      font-size: 16px;
      line-height: 18px;
      font-weight: 300;
      margin: 0 0 .5rem;
    }
  }

  &__item{
    flex:1;
    display: flex;
    align-items: center;

    &_center{
      justify-content: center;
    }

    &_padding{
      padding:4rem 0;
    }

    &-wrapper{
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding:8rem 4rem;
      height: 100vh;

      @media screen and (max-width:$zoom120){
        @media screen and (max-height:$zoom120Height){
          padding:2rem 4rem;
        }
      }

      @media screen and (max-width:$md-width) {
        padding:2rem 2rem 5rem;
        height: auto;
      }
    }
  }

  &__person{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @media screen and (max-width:$md-width) {
      margin: 0 0 3rem;
    }
  }

  &__img{
    width: 70px;
    height: 70px;
    border-radius: 100%;
    overflow: hidden;
    flex:1 0 auto;
  }

  &__name{
    font-size: 16px;
    line-height: 17px;
    margin:0 0 .5rem;
  }

  &__info{
    margin-left: 1rem;
  }

  &__position{
    font-size: 16px;
    line-height: 17px;
    max-width: 300px;
    font-weight: 300;
  }

  &__date{
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    color: #144784;
    margin: 0 0 1rem;
  }

  &__title{
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
    margin: 0 0 2rem;
  }

  &__group{
    max-width: 335px;
    width: 100%;
  }

  & .slick-dots{
    display: flex;
    flex-flow: column;
    position: absolute;
    left: 5rem;
    right: 0;
    top:calc(50% - 100px);
    bottom: unset;
    width: auto;
  }

  & .slick-dots {
    li {
      margin: .5rem 0;
    }
  }

  & .slick-dots{
    li{
      button{
        &::before{
          font-size: 50px;
          color:rgba(179, 179, 179, 0.35);
          opacity: 1;
        }
      }

      &.slick-active{
        button{
          &::before{
            opacity: 1;
            color:#19528E;
          }
        }
      }
    }
  }



}

