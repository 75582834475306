.introduction{

  &__wrapper{
    padding:0 0 2rem 0;
  }

  &__title{
    margin-bottom: 1rem;
    @media screen and (max-width:$md-width) {
      margin-left:1rem;
    }
  }

  &__list{
    display: flex;
    justify-content: space-between;
    margin:0 -1.5rem;
    padding-top:2rem;


    @media screen and (max-width:$md-width) {
      flex-flow:column;
    }
  }

  &__item{
    flex:1;
    padding:0 1.5rem;

    @media screen and (max-width:$md-width) {
      margin-bottom: 1rem;
      padding:0 2.5rem;
    }

    &-wrapper{
      border:1px solid #5DB6E9;
      padding:1.5rem;
    }

    &-title{
      font-weight: 500;
      font-size: 30px;
      line-height: 34px;
      color: #144784;
      margin:0 0 .5rem 0;
    }

    &-text{
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: #144784;
      height:30px;
    }

    &-img{
      width: 34px;
      height: 34px;
      margin:0 0 .25rem 0;
    }
  }
}