.menu{
  &-mobile{
    position: fixed;
    display: flex;
    opacity: 0;
    visibility: hidden;
    top: 0;
    right: -100%;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    background: linear-gradient(48.81deg, #E1524C 0%, #FC837B 93.37%);
    z-index:100;
    transition: $transition;
    max-width:200px;
    width:100%;
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.14), 0 6px 30px rgba(0, 0, 0, 0.12), 0 8px 10px rgba(0, 0, 0, 0.2);

    &.active{
      opacity: 1;
      visibility: visible;
      right: 0;
    }

    & ul{
      padding:4rem 0 0;
      flex-flow:column;
      width:100%;

      & li{
        padding:1rem;
        width:100%;
        margin:0;

        &:focus,
        &:hover{
          background: linear-gradient(84.33deg, #144784 -13.51%, #3598D0 101.57%);
        }

        &:last-child{
          margin:0;
        }

        a{
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.25px;
          color: #FFFFFF;
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }

    &.active{
      opacity: 1;
      visibility: visible;
      right: 0;
    }
  }

  &-closer{
    width:24px;
    height:24px;
    display: none;
    position: absolute;
    top:1rem;
    right:1rem;

    @media screen and (max-width:$lg-width){
      display: block;
    }

    svg{
      width:100%;
    }
  }

  &-item{
    width:100%;
    border-top:1px solid rgba(255, 255, 255, 0.12);
    padding:1rem;

    a{
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.25px;
      color: #FFFFFF;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
