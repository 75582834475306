.logo{
  max-width:120px;
  width:100%;
  flex:0 0 auto;

  @media screen and (max-width:$lg-width){
    max-width:70px;
  }

  svg{
    width: 75%;
    padding: .5rem 0;
    @media screen and (max-width:$lg-width){
      padding: 1rem 0;
    }

    path.st0{
      fill:#fff;
    }
  }
}

#logo{
  path{
    fill:#fff;
  }
}