.question{
  margin: 0 0 -5.5rem;

  &-wrapper{
    background: #FFFFFF;
    box-shadow: 0 6px 27px rgba(150, 150, 150, 0.25);
    border-radius: 10px;
    padding:3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:100%;

    @media screen and (max-width:$lg-width){
      flex-flow:column;
    }
  }

  &-text{
    display: flex;
    flex-flow:column;
    font-weight: 500;
    font-size: 35px;
    @media screen and (max-width:$lg-width){
      margin: 0 0 2rem;
      font-size: 24px;
    }

    span{
      font-weight: normal;
      margin:1rem 0 0;
      font-size: 16px;
      color: #4F5665;

      @media screen and (max-width:$lg-width){
        text-align: center;
        font-size: 14px;
        margin:.5rem 0 0;
      }
    }
  }

}