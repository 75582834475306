#press{
  margin:48px 0;
  @media screen and (max-width:$md-width ) {
    margin:24px 0;
  }
}
.news{
  margin:64px 0 0;

  @media screen and (max-width:$md-width ) {
    margin:48px 0 0;
  }

  &-wrapper{
    display: flex;
    flex-flow:row wrap;
  }

  &-item{
    display: flex;
    align-items: center;
    max-width: calc(50% - 12px);
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0 6px 27px rgba(150, 150, 150, 0.25);
    overflow: hidden;
    margin:0 0 36px 0;
    min-height: 225px;

    @media screen and (max-width:$md-width ) {
      max-width: 100%;
      margin:0 0 24px 0;
      flex-flow:column;
      min-height: auto;
      height: auto;
    }

    &:nth-child(2n-1){
      margin:0 24px 36px 0;

      @media screen and (max-width:$md-width ) {
        margin:0 0 24px 0;
      }
    }
  }

  &-img{
    height:100%;
    width:225px;
    background-position: center;
    background-size: cover;
    flex:1;

    @media screen and (max-width:$md-width ) {
      height:250px;
      width:100%;
      flex:unset;
    }
  }

  &-row{}
  &-col{
    display: flex;
    flex-flow:column;
    padding:24px;
    flex:2;
    height: 100%;
    justify-content: space-around;

    @media screen and (max-width:$md-width ) {
      flex:unset;
      justify-content: flex-start;
      height: auto;
    }
  }

  &-title{
    font-size: 24px;
    line-height: 32px;
    color: #5FC5FE;
    @media screen and (max-width:$md-width ) {
      margin:0 0 24px;
    }
  }

  &-preview{
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color:#666666;
  }
}