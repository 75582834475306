.study-mobile{
  display: none;
  @media screen and (max-width:$md-width) {
    display: block;
  }

  &__item{
    padding:2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-size: cover;
  }

  &__date{
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #144784;
  }

  &__course{
    margin: 0 0 1rem 0;
  }

  &__title{
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    padding:1rem 0;
  }

  &__section{
    display: flex;
    margin: 0 0 2rem 2rem;
  }

  &__group{
    display: flex;
    flex-flow: column;
  }

  &__arrow{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    cursor: pointer;

    &_active img{
      transform:rotate(270deg)!important;
    }
  }

  &__arrow img{
    transform:rotate(90deg);

  }

  &__list{
    height: 0;
    padding:0;
    margin:0;
    overflow: hidden;
    opacity: 0;
    transition:$transition;

    &_active{
      height: auto;
      overflow: visible;
      opacity: 1;
    }
  }

}