.stages{
  &__wrapper{
    padding:4rem 0;

    @media screen and (max-width:$zoom120){
      @media screen and (max-height:$zoom120Height){
        padding:3rem 0;
      }
    }

    @media screen and (max-width:$md-width) {
      padding:3rem 1.5rem;
    }
  }

  &__item{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-left:30px;
    position: relative;
    height: 100px;

    @media screen and (max-width:$zoom120){
      @media screen and (max-height:$zoom120Height){
        height: 80px;
      }
    }

    @media screen and (max-width:$md-width) {
      flex-flow:column;
      justify-content: flex-start;
      height: auto;
      padding:0 0 30px 0;
      margin-left:35px;
    }

    &:last-child{
      @media screen and (max-width:$md-width) {
        margin-bottom:70px;
      }
    }
  }

  &__title{
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #144784;
    width:40%;
    @media screen and (max-width:$md-width) {
      width:100%;
      padding:0 70px 20px 0;
    }

    &_color{
      color: #000;
    }

    &::before{
      position: absolute;
      content:'';
      width:16px;
      height:16px;
      border-radius: 100%;
      background: linear-gradient(84.33deg, #1B559B -13.51%, #3598D0 101.57%);;
      top:3px;
      left:-30px;
      @media screen and (max-width:$md-width) {
        left:-35px;
        width:11px;
        height:11px;
      }
    }
    &::after{
      position: absolute;
      content:'';
      width:1px;
      height:calc(100% - 10px);
      top:14px;
      left:-22px;
      background: #3598D0;
      @media screen and (max-width:$md-width) {
        left:-30px;
      }
    }

    &_last{
      &::after{
        display: none;
      }
    }
  }

  &__list{
    width:60%;
    @media screen and (max-width:$md-width) {
      width:100%;
    }

    &-item{
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      padding-right: 2rem;
    }
  }
}