.menu{
  @media screen and (max-width:$lg-width){
    display: none;
  }

  ul{
    display: flex;
    align-items:center;
    justify-content: center;

    li{
      margin:0 1rem;

      &:last-child{
        margin: 0 0 0 1rem;
      }

      a{
        font-weight: bold;
        font-size: 18px;
        color:#fff;
      }
    }
  }
}