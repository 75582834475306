.slider{
  padding:4rem;
  display: flex;
  flex-flow:wrap;
  align-items: flex-start;
  justify-content: space-around;

  @media screen and (max-width:$lg-width){
    padding:1rem;
  }

  &-item{
    @media screen and (max-width:$lg-width){
      margin: 0 0 2rem;
    }

    &_name{
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      margin:2rem 0 0;
      color: #0B132A;
      transition: $transition;
    }

    &_dept{
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      margin:1rem auto 0;
      color: #0B132A;
      max-width:300px;
      width:100%;
      transition: $transition;

      @media screen and (max-width:$lg-width){
        max-width:100%;
        font-size: 16px;
        line-height: 20px;
      }
    }

    &_img{
      border-radius: 10px;
      overflow: hidden;
      max-height:500px;
      transition:$transition;

      img{
        width:100%;
        height:100%;
      }
    }
  }

  & .slick-slider{
    transition: $transition;
  }
  & .slick-slide{
    transition: $transition;
    @media screen and (max-width:$lg-width){
      transform: scale(.8);
    }
  }

  & .slick-slide.slick-current.slick-active.slick-center{
    @media screen and (max-width:$lg-width){
      transform: scale(1);
    }
  }
  & .slick-slide.slick-current.slick-active{

    & .slider-item_img{
      transform: scale(.8);
      @media screen and (max-width:$lg-width){
        transform: scale(1);
      }
    }

    & .slider-item_name,
    & .slider-item_dept{
      visibility: hidden;
      opacity: 0;
      @media screen and (max-width:$lg-width){
        visibility: unset;
        opacity: unset;
      }
    }
  }

  & .slick-slide.slick-cloned,
  & .slick-active{
    & .slider-item_img {
      transform: scale(.8);
      @media screen and (max-width:$lg-width){
        transform: unset;
      }
    }

    &.active{
      & .slider-item_img {
        transform: scale(1);
        @media screen and (max-width:$lg-width){
          transform: unset;
        }
      }

      & .slider-item_name,
      & .slider-item_dept{
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.slick-prev,
.slick-next{
  top:calc(50% - 25px);
  z-index: 10;
}
.slick-next{
  &:before{
    right: 25px;
  }
}
.slick-arrow{
  &:before{
    position: absolute;
    content:'';
    background-image: url(../../assets/img/any/variant-arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    height:50px;
    width:50px;
    left:unset;
    top:0;
  }

  &.slick-prev{
    &:before{
      transform: rotateY(180deg);
    }
  }
}


.slick-arrow{
/*  background: #FFFFFF!important;
  background-color: #FFFFFF!important;
  box-shadow: 0 6px 27px rgba(150, 150, 150, 0.25);*/
  width:50px;
  height:50px;
  /*border-radius:100%;*/

  &:hover{
    /*background: #FFFFFF!important;*/
  }
}


