.banner{
  position: relative;
  height:630px;
  width:100%;
  background-size: cover;
  background-position: center;

  @media screen and (max-width:$xl-width ){
    height:60vh;
  }

  &-wrapper{
    display: flex;
    flex-flow:column;
    justify-content:center;
    height:100%;
    max-width:550px;
  }

  &-title{
    font-weight: bold;
    font-size: 72px;
    line-height: 75px;
    color: #FFFFFF;

    @media screen and (max-width:$xl-width ){
      font-size: 52px;
      line-height: 55px;
    }
    @media screen and (max-width:$sm-width ){
      font-size: 36px;
      line-height: 132.4%;
    }
  }

  &-text{
    font-weight: 500;
    font-size: 24px;
    line-height: 149.5%;
    color: #FFFFFF;
    margin:1.75rem 0 2rem;

    @media screen and (max-width:$sm-width ){
      font-size: 15px;
    }
  }
}