.footer{
  background: $primaryGradient;

  &-wrapper{
    padding:8rem 0 4rem;
    @media screen and (max-width:$lg-width){
      padding:8rem 3rem 4rem;
    }

    &__clear{
      padding:4rem 0;
    }
  }

  &-site{
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    font-weight: bold;
  }

  &-address{
    margin:1rem 0 2rem;
    font-size: 16px;
    line-height: 30px;
    color: #fff;
    max-width:400px;
    width:100%;

    @media screen and (max-width:$lg-width){
      font-size: 14px;
      line-height: 20px;
    }
  }

  &-social{
    font-size: 16px;
    line-height: 30px;
    color: #fff;

    &_list{
      margin:1rem 0;
      display: flex;
      align-items: center;

      &-item{
        margin:0 1rem 0 0;
        background:#fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width:35px;
        height:35px;
        border-radius: 100%;
        box-shadow: 0 13px 18px rgba(17, 19, 35, 0.08);

        svg{
          width:35px;
          height:35px;
        }
      }

    }
  }

}