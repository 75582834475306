.container{
  max-width:1244px;
  width:100%;
  height:100%;
  padding:0 12px;
  margin:0 auto;

  &-lg{
    max-width:1504px;
  }

  &-sm{
    max-width:1200px;
  }
}
